import { render, staticRenderFns } from "./shopInfo.vue?vue&type=template&id=f04ac55a&scoped=true&"
import script from "./shopInfo.vue?vue&type=script&lang=js&"
export * from "./shopInfo.vue?vue&type=script&lang=js&"
import style0 from "./shopInfo.vue?vue&type=style&index=0&id=f04ac55a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f04ac55a",
  null
  
)

export default component.exports